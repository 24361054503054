const formTextareasControls = document.querySelectorAll(
	".form__textarea textarea"
);

if (formTextareasControls.length > 0) {
	const formTextareaControlController = (textarea) => {
		const textareaValue = textarea.value.trim();

		if (textareaValue.length > 0) {
			textarea.parentElement.parentElement.classList.add("is-active");
		} else {
			textarea.parentElement.parentElement.classList.remove("is-active");
		}
	};

	formTextareasControls.forEach(function(formTextareaControl) {
		formTextareaControl.addEventListener("change", (e) => {
			formTextareaControlController(e.target);
		});

		formTextareaControl.addEventListener("keyup", (e) => {
			formTextareaControlController(e.target);
		});
	});
}
