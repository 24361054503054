const formConsentsAllTrigger = document.querySelector(
	".form__consents-trigger"
);

const formConsentsInputs = document.querySelectorAll(
	".form__row--consents .form__consent input"
);

if (formConsentsInputs) {
	formConsentsInputs.forEach((input) => {
		input.addEventListener("change", () => {
			const checkedInputs = document.querySelectorAll(
				".form__row--consents .form__consent input:checked"
			);

			if (checkedInputs.length === formConsentsInputs.length) {
				formConsentsAllTrigger.dataset.status = "true";
			} else {
				formConsentsAllTrigger.dataset.status = "false";
			}
		});
	});
}

if (formConsentsAllTrigger) {
	formConsentsAllTrigger.addEventListener("click", (trigger) => {
		const { status } = formConsentsAllTrigger.dataset;

		if (status === "false") {
			formConsentsInputs.forEach((input) => {
				input.checked = true;
			});

			formConsentsAllTrigger.dataset.status = "true";
		}

		if (status === "true") {
			const checkedInputs = document.querySelectorAll(
				".form__row--consents .form__consent input:checked"
			);

			if (checkedInputs.length === formConsentsInputs.length) {
				formConsentsInputs.forEach((input) => {
					input.checked = false;
				});

				formConsentsAllTrigger.dataset.status = "false";
			}
		}
	});
}
