const formInputsControls = document.querySelectorAll(".form__input input");

if (formInputsControls.length > 0) {
	const formInputControlController = (input) => {
		const inputValue = input.value.trim();

		if (inputValue.length > 0) {
			input.parentElement.parentElement.classList.add("is-active");
		} else {
			input.parentElement.parentElement.classList.remove("is-active");
		}
	};

	formInputsControls.forEach(function(formInputControl) {
		formInputControl.addEventListener("change", (e) => {
			formInputControlController(e.target);
		});

		formInputControl.addEventListener("keyup", (e) => {
			formInputControlController(e.target);
		});
	});
}
