import { displayModal } from "./modal";

const links = document.querySelectorAll("a");

if (links) {
	links.forEach(function (link) {
		link.addEventListener("click", (item) => {
			item.preventDefault();

			if (String(link.href).includes("#")) {
				const id = link.href.split("#")[1];

				displayModal(`modal-${id}`);
			}
		});
	});
}
