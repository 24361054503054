import * as Yup from "yup";
import axios from "axios";

import { displayModal } from "./components/modal";

const contactForms = document.querySelectorAll(".form");

if (contactForms.length > 0) {
  const contactFormValidationReset = (form) => {
    const wrapper = form.target;

    const inputsErrors = wrapper.querySelectorAll(".form__input--error");
    inputsErrors.forEach((element) => {
      element.classList.remove("form__input--error");
    });

    const inputsErrorsValues = wrapper.querySelectorAll(".form__input-error");
    inputsErrorsValues.forEach((element) => {
      element.textContent = "";
    });

    const hoursError = wrapper.querySelector(
      ".form__row--hours .form__row-error"
    );

    if (hoursError) {
      hoursError.classList.remove("is-active");
      hoursError.textContent = "";
    }

    const checkboxes = wrapper.querySelectorAll(".form__checkbox");
    checkboxes.forEach((checkbox) => {
      const checkboxInput = checkbox.querySelector("input");

      if (!checkboxInput.checked) {
        checkbox.classList.remove("form__checkbox--checked");
      }
    });

    const consents = wrapper.querySelectorAll(".form__consent");
    const consentsAllTrigger = wrapper.querySelector(".form__consents-trigger");
    consents.forEach((consent) => {
      const consentInput = consent.querySelector("input");

      if (!consentInput.checked) {
        if (consentsAllTrigger) {
          consentsAllTrigger.dataset.status = "false";
        }
      }
    });

    const messageError = wrapper.querySelector(".form__textarea ");

    if (messageError) {
      messageError.classList.remove("form__textarea--error");
      messageError.querySelector(".form__textarea-error").textContent = "";
    }

    const consentsErrors = wrapper.querySelectorAll(
      ".form__consent.form__consent--error"
    );
    consentsErrors.forEach((element) => {
      element.classList.remove("form__consent--error");
    });
  };

  const contactFormValidationDisplay = (form, errors) => {
    const wrapper = form.target;

    errors.inner.forEach((error) => {
      const errorInput = wrapper.querySelector(`[name="${error.path}"]`);

      if (error.path === "hours") {
        const roomsError = wrapper.querySelector(
          ".form__row--hours .form__row-error"
        );

        if (roomsError) {
          roomsError.classList.add("is-active");
          roomsError.textContent = error.message;
        }

        return;
      }

      if (error.path === "message") {
        const errorWrapper = errorInput.parentElement.parentElement;

        errorWrapper.classList.add("form__textarea--error");

        errorWrapper.querySelector(".form__textarea-error").textContent =
          error.message;

        return;
      }

      if (
        ["consent1", "consent2", "consent3", "consent4"].includes(error.path)
      ) {
        errorInput.parentElement.classList.add("form__consent--error");

        return;
      }

      if (errorInput) {
        const errorWrapper = errorInput.parentElement.parentElement;

        errorWrapper.classList.add("form__input--error");

        errorWrapper.querySelector(".form__input-error").textContent =
          error.message;
      }
    });
  };

  const contactFormSchema = Yup.object().shape({
    fullname: Yup.string().required("To pole jest wymagane"),
    email: Yup.string()
      .email("Podaj poprawny adres email")
      .required("To pole jest wymagane"),
    phone: Yup.string()
      .min(9, "Podaj poprawny numer telefonu")
      .matches(/^[\d\s+]*$/, "Podaj poprawny numer telefonu")
      .required("To pole jest wymagane"),
    hours: Yup.array().min(1, "Proszę wybrać preferowaną godzinę"),
    consent1: Yup.bool()
      .oneOf([true], "Zgoda jest wymagana")
      .required("Zgoda jest wymagana"),
    consent2: Yup.bool()
      .oneOf([true], "Zgoda jest wymagana")
      .required("Zgoda jest wymagana"),
    consent3: Yup.bool()
      .oneOf([true], "Zgoda jest wymagana")
      .required("Zgoda jest wymagana"),
  });

  contactForms.forEach((contactForm) => {
    contactForm.addEventListener("submit", (form) => {
      form.preventDefault();

      contactFormValidationReset(form);

      const submitButton = document.querySelector(".form__submit");

      const formData = new FormData(contactForm);
      const contactFormData = Object.fromEntries(formData.entries());

      if (formData.getAll("hours")) {
        contactFormData.hours = formData.getAll("hours");
      }

      contactFormSchema
        .validate(contactFormData, { abortEarly: false })
        .then(function () {
          submitButton.disabled = true;
          submitButton.classList.add("loading");

          axios({
            method: "post",
            url: "./api/mailer-main.php",
            data: contactFormData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {
              const { data } = response;

              if (data.code === 200 && data.status === "success") {
                displayModal("modal-kontakt");

                contactForm.reset();
                contactFormValidationReset(form);
              }
            })
            .catch(function (response) {
              console.error(response);
            })
            .finally(() => {
              submitButton.disabled = false;
              submitButton.classList.remove("loading");
            });
        })
        .catch(function (errors) {
          contactFormValidationDisplay(form, errors);
        });
    });
  });
}
