import "../scss/main.scss";

import "./components/formInput";
import "./components/formTextarea";
import "./components/formCheckbox";
import "./components/formConsent";
import "./components/formConsentsAll";
import "./components/modal";
import "./components/links";

import "./form";
import "./utils";
