const formConsentsTriggers = document.querySelectorAll(
	".form__consent-trigger"
);

if (formConsentsTriggers.length > 0) {
	formConsentsTriggers.forEach(function(formConsentTrigger) {
		formConsentTrigger.addEventListener("click", (item) => {
			item.target.parentElement.parentElement.classList.toggle("is-active");
		});
	});
}
