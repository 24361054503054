const formCheckboxesInputs = document.querySelectorAll(".form__checkbox input");
const formCheckobxesInputsHours = document.querySelectorAll(
	".form__row--hours input"
);

if (formCheckboxesInputs.length > 0) {
	formCheckboxesInputs.forEach(function (formCheckboxInput) {
		formCheckboxInput.addEventListener("change", (item) => {
			item.preventDefault();

			formCheckobxesInputsHours.forEach(function (formCheckobxesInputsHour) {
				formCheckobxesInputsHour.checked
					? formCheckobxesInputsHour.parentElement.classList.add(
							"form__checkbox--checked"
					  )
					: formCheckobxesInputsHour.parentElement.classList.remove(
							"form__checkbox--checked"
					  );
			});
		});
	});
}
