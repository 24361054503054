const modal = document.querySelectorAll(".modal");
const modalButton = document.querySelectorAll(".modal__btn");
const htmlTag = document.getElementsByTagName("html")[0];

export const hideModal = () => {
	const modal = document.querySelectorAll(".modal--visible");

	if (!modal) {
		return;
	}

	modal.forEach((modal) => {
		modal.classList.remove("modal--visible");
	});

	htmlTag.style.overflow = "auto";
};

export const displayModal = (modalId) => {
	const modal = document.getElementById(modalId);

	if (!modal) {
		return;
	}

	htmlTag.style.overflow = "hidden";

	modal.classList.add("modal--visible");
};

if (modalButton.length > 0) {
	modalButton.forEach((item) => {
		item.addEventListener("click", () => {
			hideModal();
		});
	});
}

if (modal.length > 0) {
	modal.forEach((item) => {
		item.addEventListener("click", (e) => {
			if (e.target === item) {
				hideModal();
			}
		});
	});
}
